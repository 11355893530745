import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "@emotion/styled";
import tw from "twin.macro";

import ButtonIcon from "../Button/ButtonIcon";

const StyledTabs = styled.div`
  .react-tabs__tab-list {
    ${tw`relative mb-10 hidden h-[50px] max-w-[340px] rounded-6xl bg-secondary-500/10 text-center shadow-3xl md:flex items-center`}
  }
  .react-tabs__tab {
    ${tw`z-10 flex-1 font-heading font-semibold no-underline text-secondary-500 hover:text-secondary-500 cursor-pointer transition-colors ease-linear duration-300`}
  }
  .react-tabs__tab--selected {
    ${tw`text-white hover:text-white`}
  }
  .react-tabs__tab-panel {
    ${tw`md:hidden`}
  }

  .react-tabs__tab-panel--selected {
    ${tw`md:block`}
  }
`;

const StatesWeServe = ({ location, className }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [purchase, setPurchase] = useState(true);
  const [refinance, setRefinance] = useState(false);

  useEffect(() => {
    if (selectedIndex === 0) {
      setPurchase(true);
      setRefinance(false);
    } else if (selectedIndex === 1) {
      setRefinance(true);
      setPurchase(false);
    }
  }, [selectedIndex]);

  return (
    <section className={` ${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          <div>
            <StyledTabs>
              <Tabs
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndex(index)}
                forceRenderTabPanel={true}
              >
                <TabList>
                  <Tab>Purchase</Tab>
                  <Tab>Refinance</Tab>
                  <div
                    className={`absolute bottom-0 h-full w-1/2 rounded-6xl bg-secondary-500 transition-all duration-300 ease-linear ${
                      selectedIndex === 0
                        ? "left-0"
                        : selectedIndex === 1
                        ? "left-1/2"
                        : "left-0"
                    }`}
                  ></div>
                </TabList>

                <div className="space-y-20 md:space-y-0">
                  <TabPanel>
                    <div className="md:hidden">
                      <StaticImage
                        src="../../images/1.0 Homepage/3.0 Purchase.png"
                        loading="lazy"
                        width={560}
                        className="mb-10"
                      />

                      <div className="mb-6 inline-flex rounded bg-secondary-500/10 px-2 py-1.5 font-heading text-sm font-bold uppercase tracking-wider text-secondary-500">
                        Purchase
                      </div>
                    </div>

                    <h2>
                      I’m Ready To Buy a Home {location && `in ${location}`}
                    </h2>
                    <p>
                      Are you ready to secure a loan and start looking for your
                      new home {location && `in ${location}`}? As a full-service
                      mortgage lender, we can get you started. View loan
                      programs and mortgage rate options, plus learn how you can
                      apply.
                    </p>
                    <ButtonIcon
                      href="/home-purchase/"
                      text="Learn More"
                      altStyle={2}
                    />
                  </TabPanel>
                  <TabPanel>
                    <div className="md:hidden">
                      <StaticImage
                        src="../../images/1.0 Homepage/3.1 Refinance.png"
                        loading="lazy"
                        width={560}
                        className="mb-10"
                      />

                      <div className="mb-6 inline-flex rounded bg-primary-500/10 px-2 py-1.5 font-heading text-sm font-bold uppercase tracking-wider text-primary-500">
                        Refinance
                      </div>
                    </div>

                    <h2>I Want To Refinance</h2>
                    <p>
                      Are you interested in refinancing your{" "}
                      {location && location} home to lower your interest rate,
                      shorten your loan term, consolidate debt, or get cash for
                      a big project like a home renovation? Get started here.
                    </p>
                    <ButtonIcon href="/home-refinance/" text="Learn More" />
                  </TabPanel>
                </div>
              </Tabs>
            </StyledTabs>
          </div>
          <div className="hidden md:block">
            <div className={`${purchase ? "block" : "hidden"}`}>
              <StaticImage
                src="../../images/1.0 Homepage/3.0 Purchase.png"
                loading="lazy"
                width={560}
              />
            </div>
            <div className={`${refinance ? "block" : "hidden"}`}>
              <StaticImage
                src="../../images/1.0 Homepage/3.1 Refinance.png"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatesWeServe;
